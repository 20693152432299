.input {
    width: 100%;
    height: 45px;
    padding: 12px;
    margin-right: 5px;
    border-radius: 5px;
    border: 1.5px solid lightgrey;
    outline: none;
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: 0px 0px 20px -18px;
  }
  
  .input:hover {
    border: 2px solid lightgrey;
    box-shadow: 0px 0px 20px -17px;
  }
  
  .input:active {
    transform: scale(0.95);
  }
  
  .input:focus {
    border: 2px solid grey;
  }




.input[type="checkbox"] {
    display: none;
}
.input[type="checkbox"] + label {
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;
  display: inline-block;
  line-height: 25px;
  font-size: 14px;
}

.input[type="checkbox"] + label::before {
  content: "";
  position: absolute;
  background-color: white;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 1.5px solid lightgrey;
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.input[type="checkbox"]:hover + label::before {
  border: 2px solid lightgrey;
}

.input[type="checkbox"]:checked + label::before {
  background-color: #555;  /* color when checkbox is checked */
}

.input[type="checkbox"]:focus + label::before {
  border: 2px solid grey;
}
.input[type="checkbox"]:checked + label::after {
  content: "✓";
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 18px;
}