.main-page{
    padding: 1%;
    display: flex;
    box-sizing: border-box;
}
.right-container{
    flex:1;
    margin:50px 50px 50px 10px;
    display: flex;
    justify-content: top;
    max-width:50%;
    flex-direction: column;
}
.form-container{
    position: static;
    flex:1;
    margin:50px 10px 50px 50px;
    max-width:50%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.input-wrapper{
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}
.result{
    margin-top: 20px;
    font-size:30px;
}
.description{
    font-size:22px;
}
@media screen and (max-width: 800px){
    .main-page{
       flex-direction: column;
       padding-top:5%;
    }
    .form-container{
        max-width: 100%;
        margin:10px;
    }
    .right-container{
        max-width: 100%;
        margin:10px;
    }
    .feedback-container{
        margin: 2px!important;;
    }
    .right-container{
      margin: 10px!important;
    }
}
.feedback-button {
    position: fixed;
    top: -10px;
    right: 0;
    padding: 20px;
    z-index:999;
  }
.feedback-container{
    flex:1;
    margin:50px;
    max-width:100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
form{
    display:flex;
    flex-direction: column;
    row-gap: 20px;
}
.form-row{
        box-sizing: border-box;
        padding: 2rem;
        border-radius: 5px;
        background-color: hsl(0, 0%, 100%);
        border: 4px solid hsl(0, 0%, 90%);
        display: flex;
        flex-direction: column;
}
.result-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .result-table th, .result-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
  }
  
  .result-table th {
    background-color: #f2f2f2;
  }
  .row-title {
    display: flex;
    justify-content: space-between;
  }
  .title{
        display: flex;
        align-items: center;
        font-size:14px;
  }
  h3 {
    margin: 0; 
  }
  .delete-button{
    background-color: #e68f8e!important;
    font-size:14px!important;
    width: 100px!important;
    padding: 6px!important;
  }
.add-button{
  background-color: #5cb85c!important;
  font-size:14px!important;
  width: 100px!important;
  padding: 6px!important;
}
  .calc-button, .print-button{
    background-color: #428dce!important;
  }
  .pay-button{
    background-color: #5cb85c!important;
  }

  .delete-button,
  .pay-button,
  .calc-button,
  .add-button,
  .print-button{
    color:#fff!important;
  }


  .delete-button:hover{
    background-color: black;
  }
  .buttons-wrapper{
    display: flex;
  }
  @media screen and (max-width: 1100px){
    .form-row{
      border-radius: 5px!important;
      padding: 0.5rem!important;
    }
    .css-o6h92p-control{
      height: 38px!important;
    }
    .right-container{
      margin: 50px 10px 10px 10px;
    }

    .form-container{
      margin: 50px 10px 10px 10px;
    }
  }

  .results-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 20px;
  }
  .rechizite-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 0.2rem;
    border-radius: 5px;
    background-color: hsl(0, 0%, 100%);
    border: 4px solid hsl(0, 0%, 90%);
  }
  .hide{
    display: none!important;
  }
  .disabled{
    opacity:0.6;
  }
  .title-buttons-wrapper{
    display: flex;
  }
  @media print {

    .buttons-wrapper {
      display: none;
    }
    .form-container{
      display: none;
    }
    .right-container{
      max-width: 100%;
    }
    nav{
      display: none;
    }
    .footer{
      display:none;
    }
    .feedback-button{
      display: none;
    }
    .input-wrapper{
      display: none;
    }
  }