/* ToggleButton.css */
@keyframes fadeAnimation {
  from {
    opacity: 0;
    transform: translateX(-10px); /* Optional: Initial position */
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.toggle-button {
  position: relative;
  display: flex;
  width: 100%;
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
}

.toggle-button input {
  opacity: 0;
  width: 0;
  height: 0;
}

.label-left,
.label-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  width: 50%;
  text-align: center;
  color: #000;
}

.label-left {
  left: 0;
}

.label-right {
  right: 0;
}

.left-side,
.right-side {
  width: 50%;
  height: 100%;
  transition: opacity 0.3s ease, transform 0.3s ease; /* Transition for smooth fade-in/fade-out and movement */
}

.checked .left-side {
  animation: fadeAnimation 0.3s ease-in-out; /* Apply the fade-in/fade-out animation */
  opacity: 0;
  transform: translateX(-10px); /* Optional: Initial position */
}

.checked .right-side {
  animation: fadeAnimation 0.3s ease-in-out; /* Apply the fade-in/fade-out animation */
  opacity: 1;
  transform: translateX(0);
}

.checked {
  background-color: #17a2b8;
  color: #fff;
}
