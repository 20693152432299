
.button28 {
  appearance: none;
  background-color: transparent;
  border: 1px solid lightgrey;
  border-radius: 5px;
  box-sizing: border-box;
  color: var(--eerie-black);
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-width: 0;
  outline: none;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}

.button28:disabled {
  pointer-events: none;
}

.button28:hover {
  color: #fff;
  background-color: #343a40;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.button28:active {
  box-shadow: none;
  transform: translateY(0);
}

@media screen and (max-width: 1100px) {
  .button28 {
    font-size: 14px; 
    padding: 10px 10px; 
  }
}