* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.App{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    margin: 0;
}

#root{
    display: flex;
    justify-content: center;
    color: black;
    font-family: Montserrat,sans-serif;
    font-size: 16px;
    line-height: 1.6;
    min-height: 100%;
}
.logo-img{
    width: auto;
    height: 100%;
    flex: none;
}
img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}
.logo{
    width: auto;
    height: 2rem;
    flex-wrap: nowrap;
    display: flex;
    cursor: pointer;
}

.main-content {
    flex: 1 0 auto;  
    background-color: var(--platinum);
}

p.subHead{
    font-size:18px;
    text-align: center;
}

.focused-table {
    outline: none;
  }
  a{
    color:#0d6efd
  }