.select {
    width: 100%;
    height: 45px;
    padding: 12px;
    border-radius: 5px;
    border: 1.5px solid lightgrey;
    outline: none;
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: 0px 0px 20px -18px;
    color: black;
    background-color: white;
  }
  
  .select:hover {
    border: 2px solid lightgrey;
    box-shadow: 0px 0px 20px -17px;
  }
  
  .select:active {
    transform: scale(0.95);
  }
  
  .select:focus {
    border: 2px solid grey;
  }
  .select option {
    background-color: white;
    color: black;
    padding: 8px 12px;
}

.select option:hover {
    background-color: lightgrey;
    color: black;
}