.footer{
    background-color:white;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    height: 100%;    
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
}
.copyright{
    text-align: center;
    flex: 1;
}
.footer-container{
    width: 100%;
    max-width: 1180px;
    min-height: 30px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    
}
.footer-flex{
    justify-content: space-between;
    align-items: center;
    display: flex;
}

@media screen and (max-width: 935px){
.footer-flex {
    grid-row-gap: 15px;
    flex-direction: column;
    justify-content: center;
}
.footer {
    text-align: center;
}
}
.actualizat{
    text-align: center;
}
.app-status{
    color:crimson;
    font-size: 24px;
}