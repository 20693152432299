.pay-container {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-between;
    width: 100%;
  }
  
  .card {
    text-decoration: none;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 10px;
    padding: 15px;
    width: 100%;
    background-color: #f9f9f9;
    transition: background-color 0.3s;
  }
  
  .card:hover {
    background-color: #e0e0e0;
  }
  
  .text-container {
    color: #333;
  }
  